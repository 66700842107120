import React, { useState, useEffect } from 'react';
import NavBar from '../../layout/navbar/navbar';
import DashboardImg from '../../../assets/dashboard/web-development.png';
import axios from 'axios';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { FaHandPointRight } from 'react-icons/fa';
import { BiAnalyse } from 'react-icons/bi';
import { Container, Modal, Form, Alert, Button, Spinner } from 'react-bootstrap';
import { useRealmApp } from '../../RealmApp';
import './dashboard.css';
import { hearAboutUs } from '../dropdown-options/hear-about-us';
import { knowYouBetter } from '../dropdown-options/know-you-better';
import { inquiry } from '../dropdown-options/inquiry';

// class Dashboard extends React.Component
const Dashboard = () => {

    const { currentUser, logInAnonymous } = useRealmApp();

    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // realm functions used in current component
    const { getDashboardStatistics, filterActiveCampaignContacts } = currentUser.functions;

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // hook for dashboard statistics
    const [dashboardStatistics, setDashboardStatistics] = useState({
        totalInvestors: 0,
        totalCompanies: 0,
        totalActiveCompanies: 0,
        totalInactiveCompanies: 0,
        totalProducts: 0,
        reasonsForFailure: 0,
        totalUsers: 0,
        lastUpdatedDocument: [],
        activeCompanies: 0,
        activeCompaniesStatusUnknown: 0,
        shutteredCompanies: 0,
        shutteredCompaniesReasonsForFailure: 0,
        investorsLossesInvestors: 0,
        investorsLossesFailedInvestments: 0,
        discontinuedProducts: 0,
        discontinuedProductsReasonsForFailure: 0,
        productsShowcase: 0,
        productsShowcaseStatusUnknown: 0,
        postmortemAnalysis: 0,
        postmortemAnalysisReasonsForFailure: 0,
        portfolioAnalysisCompanies: 0,
        portfolioAnalysisInvestors: 0,
        competitiveAnalysis: 0,
        ideaValidation: 0,
        veryEarlyStageFunding: 0,
        earlyStageFunding: 0,
        growthStageFunding: 0,
        lateStageFunding: 0,
        postIpoStageFunding: 0,
        releaseNote: "roiquant",
        releaseNoteUrl: "#"
    });

    // hook for date
    const [lastUpdatedDate, setDate] = useState("");

    // aws activate modal

    // hook for modal
    const [show, setShow] = useState(false);

    // hook for verify form
    const [verify, setVerify] = useState({
        firstName: "",
        lastName: "",
        email: "",
        awsAvailable: "",
        howDidYouHearAboutUs: "",
        other: ""
    });

    // function to hide modal
    function hideModal() {

        setShow(false);
        setVerify({
            firstName: "",
            lastName: "",
            email: "",
            awsAvailable: "",
            howDidYouHearAboutUs: "",
            other: ""
        });
    }

    // function to update verify
    function changeVerify(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of verify with desired changes
        let verifyCopy = { ...verify };

        verifyCopy[name] = value;
        setVerify({ ...verifyCopy });
    }

    // function to submit verify
    async function submitVerifyButton(e) {
        e.preventDefault();

        // console.log(verify);

        // loading message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await filterActiveCampaignContacts(verify);

            // console.log(response);


            // success message
            setAlert(true);
            setMessage(<>
                <div style={{ paddingTop: "10px" }}>
                    <p style={{ fontSize: "16px", color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Yay, your pre-application is successful!</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Congratulations! We've sent you an email with detailed information and our unique Activate Provider Organization ID. Kindly follow the instructions carefully to submit your application on AWS Activate Console.</p>
                </div>

                <div style={{ fontSize: "12px" }}>
                    Important note:
                    <ul>
                        <li>
                            Currently, the AWS Activate programs are only available for our virtual preseed startup competition's participants.
                        </li>
                        <li>
                            We'll be offering the AWS Activate programs to our customers soon in the Q1 2023.
                        </li>
                        <li>
                            Please stay tuned for further announcements. If you have any questions, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                        </li>
                    </ul>
                </div>
            </>);
        } catch (err) {
            // error message
            setAlert(true);
            if (err.error.includes("Contact not found.")) {
                // To-do: Handle error when contact does not exist in the list of contacts
                setMessage(<>
                    <div style={{ paddingTop: "10px" }}>
                        <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Sorry, your pre-application is unsuccessful</p>
                        <p style={{ marginLeft: "28px", fontSize: "14px" }}>We've sent you an email explaining why your pre-application was unsuccessful and how to successfully apply for the AWS Activate programs. Please follow the instructions carefully to re-submit your application.</p>
                    </div>

                    <div style={{ fontSize: "12px" }}>
                        Important note:
                        <ul>
                            <li>
                                Currently, the AWS Activate programs are only available for our virtual preseed startup competition's participants.
                            </li>
                            <li>
                                We'll be offering the AWS Activate programs to our customers soon in the Q1 2023.
                            </li>
                            <li>
                                Please stay tuned for further announcements. If you have any questions, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                            </li>
                        </ul>
                    </div>
                </>);
            } else {
                if (err.error.includes("Database error")) {
                    // To-do: Handle error when database operations failed
                    setMessage(<>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> An error occurred while saving to database</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be network connection issues between our server and database or database outage. Please re-submit your pre-application. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>

                        <div style={{ fontSize: "12px" }}>
                            Important note:
                            <ul>
                                <li>
                                    Currently, the AWS Activate programs are only available for our virtual preseed startup competition's participants.
                                </li>
                                <li>
                                    We'll be offering the AWS Activate programs to our customers soon in the Q1 2023.
                                </li>
                                <li>
                                    Please stay tuned for further announcements. If you have any questions, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                                </li>
                            </ul>
                        </div>
                    </>);
                } else {
                    // To-do: Handle other error
                    setMessage(<>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Please re-submit your pre-application. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>

                        <div style={{ fontSize: "12px" }}>
                            Important note:
                            <ul>
                                <li>
                                    Currently, the AWS Activate programs are only available for our virtual preseed startup competition's participants.
                                </li>
                                <li>
                                    We'll be offering the AWS Activate programs to our customers soon in the Q1 2023.
                                </li>
                                <li>
                                    Please stay tuned for further announcements. If you have any questions, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                                </li>
                            </ul>
                        </div>
                    </>);
                }
            }
        };

        hideModal();
    };

    // useEffect function to get dashboard stats
    useEffect(async () => {
        try {
            let response = await getDashboardStatistics();

            let stats = JSON.parse(response.body);

            if (stats.lastUpdatedDocument != []) {
                var monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let dateString = new Date(stats.lastUpdatedDocument.map((doc) => doc.registerDate)[0]);
                var dateFormat = dateString.getDate() + ' ' + monthArray[dateString.getMonth()] + ' ' + dateString.getFullYear();

                setDate(dateFormat);
            }

            setDashboardStatistics(JSON.parse(response.body));
            // console.log("current stats:", dashboardStatistics);

            closeAlert();
        } catch (err) {
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>);
        }
    }, []);

    // get in touch modal

    // hook for pop up card
    const [showCard, setShowCard] = useState(false);

    // function to show pop up card
    function displayCard() {
        setShowCard(true);
    }

    // function to close the pop up card and alerts
    function hideCard() {
        setShowCard(false);
    }

    const [subscription, setSubscription] = useState({
        email: '',
        firstName: '',
        lastName: '',
        knowYouBetter: '',
        hearAboutUs: '',
        recommended: '',
        other: '',
        inquiry: '',
        others: '',
        tellusmore: '',
        id: 13 // id of get in touch contacts list
    })

    // function to update form
    function handleChange(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let subscriptionCopy = { ...subscription };

        subscriptionCopy[name] = value;
        setSubscription({ ...subscriptionCopy });
    }

    // function to submit client's form as a POST request to backend
    const handleSubmit = async (e) => {
        e.preventDefault();

        hideCard();

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const options = await axios.request({
                method: 'POST',
                url: 'https://data.mongodb-api.com/app/flipidea-3-pputk/endpoint/saveContactToBrevo',
                data: JSON.stringify({
                    subscription
                }),
                headers: { 'Content-Type': 'application/json' }
            })

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckCircle className="alert-icon" /> Thank you for reaching out!</p>
                <p style={{ marginLeft: "28px" }}>We'll be in touch shortly.</p>
            </div>);

            setSubscription({
                email: '',
                firstName: '',
                lastName: '',
                knowYouBetter: '',
                hearAboutUs: '',
                recommended: '',
                other: '',
                inquiry: '',
                others: '',
                tellusmore: '',
                id: 13 // id of get in touch contacts list
            })
        } catch (error) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>An unknown error occurred. Please try again. If error persists, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>);
        }
    }

    // render() {
    return (
        <>
            <NavBar />

            <div id="dashboard">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="dashboardcompanycard dashboardrow dashboardml1 dashboardmr0">
                        <div className="dashboardmaincard dashboardcard dashboardfmain">
                            <p className="dashboardcardheadermain">Numbers at a glance
                                <sup data-tip="Collections of multiple datasets in roiquant database.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>

                            <div className="dashboardmaincardrow1">
                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total investors</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalInvestors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total active companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalActiveCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total inactive companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalInactiveCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total products</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalProducts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Unique reasons for shutdown</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.reasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total users</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Last updated</span>
                                    <br />
                                    <span className="dashboardcarddata">{lastUpdatedDate}</span>
                                </div>
                            </div>

                            <div className="dashboardmaincardrow2">
                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total investors</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalInvestors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total active companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalActiveCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                            </div>

                            <div className="dashboardmaincardrow2">
                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total inactive companies</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalInactiveCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total products</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalProducts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Unique reasons for shutdown</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.reasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>
                            </div>

                            <div className="dashboardmaincardrow2">
                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Total users</span>
                                    <br />
                                    <span className="dashboardcarddata">{dashboardStatistics.totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                </div>

                                <div className="dashboardmaincards">
                                    <span className="dashboardcardlabel">Last updated</span>
                                    <br />
                                    <span className="dashboardcarddata">{lastUpdatedDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashboardcompanycard2 dashboardrow dashboardml1 dashboardmr0">
                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/sourcing/funding/very-early-stage" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Very-early stage funding
                                        <sup data-tip="Search for funding with our founder-friendly collection of angel investor, micro venture capital, and other very-early stage funding sources.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Financing sources</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.veryEarlyStageFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a >
                        </div >

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/sourcing/funding/early-stage" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Early stage funding
                                        <sup data-tip="Search for funding with our founder-friendly collection of accelerator, venture capital, and other early stage funding sources.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Financing sources</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.earlyStageFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/sourcing/funding/growth-stage" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Growth stage funding
                                        <sup data-tip="Search for funding with our founder-friendly collection of revenue-based financing, growth venture capital, and other growth stage funding sources.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Financing sources</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.growthStageFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a >
                        </div >

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/sourcing/funding/late-stage" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Late stage funding
                                        <sup data-tip="Search for funding with our founder-friendly collection of family office, private equity, and other late stage funding sources.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Financing sources</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.lateStageFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/sourcing/funding/post-ipo-stage" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Post-IPO stage funding
                                        <sup data-tip="Search for funding with our founder-friendly collection of hedge fund, sovereign wealth fund, and other post-IPO funding sources.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Financing sources</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.postIpoStageFunding.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a >
                        </div >

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/data/companies" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Active companies
                                        <sup data-tip="Understand the business offerings in your competitive landscape by browsing through our collection of active companies.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Active companies</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.activeCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Status unknown</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.activeCompaniesStatusUnknown.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/data/shuttered-companies" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Shuttered companies
                                        <sup data-tip="Discern meaningful insights from our collection of shuttered companies.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Inactive companies</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.shutteredCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Reasons for shutdown</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.shutteredCompaniesReasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/data/investors-losses" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Investors' losses
                                        <sup data-tip="Investigate loss of investments from our collection of failed investments.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Investors</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.investorsLossesInvestors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Failed investments</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.investorsLossesFailedInvestments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/data/discontinued-products" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Discontinued products
                                        <sup data-tip="Discover problem-solving creativity from our collection of inactive products.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Dead products</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.discontinuedProducts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Reasons for discontinuation</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.discontinuedProductsReasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/data/products-showcase" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Products showcase
                                        <sup data-tip="Inspire to create value-added offering by browsing through our collection of active products">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Active products</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.productsShowcase.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Status unknown</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.productsShowcaseStatusUnknown.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/startup-intel/postmortem-analysis" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Postmortem analysis
                                        <sup data-tip="Examine the pitfalls and mistakes others had made with our structured frameworks.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Inactive companies</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.postmortemAnalysis.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Reasons for shutdown</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.postmortemAnalysisReasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/venture-intel/portfolio-analysis" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Portfolio analysis
                                        <sup data-tip="Evaluate the performance and relationships of portfolio investments with our portfolio risk analytics powered by data science.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Portfolio companies</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.portfolioAnalysisCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>

                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Investors</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.portfolioAnalysisInvestors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/competitive-intel/competitive-landscape" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Competitive landscape
                                        <sup data-tip="Define your competitive edge by analyzing your markets with our AI-powered competitive analytics.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Companies</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.competitiveAnalysis.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="dashboardcardrow" style={{ paddingBottom: "20px" }}>
                            <a href="/tools/founders/idea-validation" className="dashboardcardlink">
                                <div className="dashboardcard dashboardf dashboardimgthumbnail dashboardfxlayout1">
                                    <p className="dashboardcardheader">Idea validation
                                        <sup data-tip="Refine your conceptual ideas into viable and differentiated product-or-solution offering with our AI-powered idea checker.">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </p>

                                    <div className="dashboardsubcardrow">
                                        <div className="dashboardsubcards">
                                            <span className="dashboardcardlabel">Entities</span>
                                            <br />
                                            <span className="dashboardcarddata">{dashboardStatistics.ideaValidation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="dashboardbody">
                        <div className="dashboardbodyleft">
                            <div className="dashboardwidget">
                                <p style={{ fontSize: "20px", fontWeight: "bold" }}>Build, launch, and scale your startup with AWS Activate Portfolio benefits</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Credits</span>: Subscribe to roiquant's subscription plans and pay with annual billing to redeem USD $5,000 or USD $10,000 in AWS Activate credits as perks to get started on AWS cloud at zero cost</p>
                                <p style={{ fontSize: "16px" }}><ul style={{ paddingLeft: "17px" }}><li>As a valued startup affiliated with roiquant, you may apply for up to USD $100,000 in AWS Activate credits to help cover your AWS service usage whenever you're ready</li></ul></p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Support</span>: Receive the right support from AWS Developer and AWS Business Support plans</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Personalized feed</span>: Receive advice and news tailored to your industry, product stage, business needs, and technical requirements</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Exclusive discounts</span>: Claim special offers for the best tools to manage your business, including Atlassian, Slack, Twilio, and more</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Customer acquisition</span>: Accelerate user adoption through the Amazon network, including Amazon Advertising, AWS Marketplace, Amazon Launchpad, and more</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> <span style={{ fontWeight: "bold" }}>Co-marketing opportunities</span>: Get tapped for exclusive content opportunities, including being featured on the AWS Startups Blog and speaking at AWS Summits and AWS re:Invent</p>

                                <div style={{ textAlign: "center" }}>
                                    <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">
                                        <button className="referralctabutton glintanimation">Pay annually to redeem AWS Activate credits!</button>
                                    </a>
                                </div>
                            </div>

                            <div className="dashboardwidget" style={{ marginTop: "10px" }}>
                                <p style={{ fontSize: "20px", fontWeight: "bold" }}>Join our referral program and get rewarded <span className="sidebarbetalabel">BETA</span></p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> Refer roiquant to your friends and gain free access to our subscription plans</p>
                                <p style={{ fontSize: "16px" }}><FaHandPointRight style={{ color: "gold" }} /> For every successful referral, you'll earn rewards credits</p>

                                <div style={{ textAlign: "center" }}>
                                    <a href="/referral-program">
                                        <button className="referralctabutton glintanimation">Invite your friends and earn rewards today!</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="dashboardbodyright">
                            <div className="dashboardwidget">

                                <div className="dashboardimage">
                                    <img src={DashboardImg} alt="" />
                                </div>

                                <div>
                                    <h2>
                                        <p style={{ fontWeight: "bold" }}>
                                            Welcome to roiquant!
                                        </p>

                                        <p style={{ fontSize: "20px" }}>
                                            Check out what's new with <a href={dashboardStatistics.releaseNoteUrl} target="_blank" rel="noopener noreferrer">
                                                {dashboardStatistics.releaseNote}
                                            </a> release, which includes issues fixes, new features, and updates.
                                        </p>

                                        <p style={{ fontSize: "20px" }}>
                                            If you have any feedback, feel free to <a href id="sharewithus" onClick={(e) => displayCard()}> share with us</a>.
                                        </p>

                                        <p style={{ fontSize: "20px" }}>
                                            If you'd like to request for additional charts, <a href id="letusknow" onClick={(e) => displayCard()}> let us know</a> the research questions and we'll consider them.
                                        </p>
                                    </h2>
                                    <span className="info-notes">
                                        Note:
                                        <ul style={{ paddingLeft: "17px" }}>
                                            <li id="notes-points">
                                                Due to large datasets, our real-time analytics (idea checker, competitive landscape, and co-investors analysis) might take up to 1 min to compute and show results.
                                            </li>
                                            <li id="notes-points">
                                                We're using advanced analytics and intelligent text analytics to analyze your inputs and queries. That said, we're improving our tech to reduce waiting time.
                                            </li>
                                            <li id="notes-points">
                                                Some of our datasets may not be accurately labelled or sorted because our data preprocessing and data wrangling are semi-automated (not fully intelligent yet).
                                            </li>
                                            <li id="notes-points">
                                                Under no obligations, roiquant (rói-kwänt) offers free subscription ($0 forever) with basic limited functionality to use <a
                                                    href="https://www.roiquant.com/products" target="_blank" rel="noopener noreferrer">our products</a>.
                                            </li>
                                            <li id="notes-points">
                                                We're currently learning what it costs to operate <a
                                                    href="https://www.roiquant.com/solutions" target="_blank" rel="noopener noreferrer">our solutions</a> and how our products are being used by our global community. Therefore, along the way we'll be asking for your feedback because we aim to build great problem-solving solutions that everyone can afford.
                                            </li>
                                            <li id="notes-points">
                                                The results of our idea validation analysis are currently limited to show only 50 competitive companies (with similar business offerings) per analysis for both freemium users and paying customers. We'll soon increase the limit to show more competitive companies for our paying customers.
                                            </li>
                                            <li id="notes-points">
                                                <a href="https://viewpoints.roiquant.com/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>: Our tools and analytics features are subject to continuous research and development to improve its accuracy and analytics systems.
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* alert for dashboard */}

                    {alert
                        ? (<Alert className="error-message floating-alert" variant="light">
                            <div>
                                <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                                {message}
                            </div>
                        </Alert>)
                        : ""
                    }

                    {/* aws activate modal */}

                    <Modal className="modal-div ideacheckernda" show={show} onHide={hideModal} backdrop="static" fullscreen="sm-down" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton style={{ borderBottom: "none" }} />

                        <Modal.Body>

                            <div style={{ paddingBottom: "20px" }}>
                                <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>Build and grow with USD $25,000 in AWS credits</p>

                                <p style={{ textAlign: "left", fontSize: "18px" }}>In collaboration with AWS Activate, we're on a mission to help you succeed</p>
                            </div>

                            <div>
                                <Form>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <Form.Group controlId="formBasicFirstName" style={{ width: "50%", paddingRight: "1.5%" }}>
                                            First name<sup>*</sup>
                                            <Form.Control onChange={changeVerify} required maxlength="20" className="inputbox" type="text" value={verify.firstName} name="firstName" placeholder="Enter your first name" />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicLastName" style={{ width: "50%" }}>
                                            Last name<sup>*</sup>
                                            <Form.Control onChange={changeVerify} required maxlength="20" className="inputbox" type="text" value={verify.lastName} name="lastName" placeholder="Enter your last name" />
                                        </Form.Group>
                                    </div>

                                    <Form.Group controlId="formBasicEmail">
                                        Email<sup>*</sup>
                                        <Form.Control onChange={changeVerify} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required className="inputbox" type="email" value={verify.email} name="email" placeholder="Enter your email address" />
                                        <Form.Text muted style={{ paddingLeft: "5%" }}><span>Please use the same email address that you've registered for roiquant's account and preseed startup competition, to apply for the AWS Activate programs. Failure to do so will result in non-approval.</span></Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formAwsAvailable">
                                        Are you aware that the AWS Activate programs are currently only available for our virtual preseed startup competition's participants?<sup>*</sup>
                                        <Form.Control as="select" onChange={changeVerify} value={verify.awsAvailable} name="awsAvailable">
                                            <option></option>
                                            <option>Yes, I am</option>
                                            <option>No, I don't</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="formHearAboutUs">
                                        How did you know that roiquant is an official AWS Activate Provider?<sup>*</sup>
                                        <Form.Control as="select" onChange={changeVerify} value={verify.howDidYouHearAboutUs} name="howDidYouHearAboutUs">
                                            <option></option>
                                            {
                                                hearAboutUs.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    {
                                        verify.howDidYouHearAboutUs === "Other"
                                            ? (
                                                <Form.Group controlId="formBasicOther">
                                                    <Form.Control onChange={changeVerify} required className="inputbox" type="text" value={verify.other} name="other" placeholder="Please tell us more" />
                                                </Form.Group>)
                                            : ""
                                    }
                                </Form>

                                <div>
                                    <span style={{ fontSize: "14px" }}>Important note:</span>
                                    <ul style={{ fontSize: "14px" }}>
                                        <li>
                                            Currently, the AWS Activate programs are only available for our virtual preseed startup competition's participants.
                                        </li>
                                        <li>
                                            We'll be offering the AWS Activate programs to our customers soon in the Q1 2023.
                                        </li>
                                        <li>
                                            Please stay tuned for further announcements. If you have any questions, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                                        </li>
                                    </ul>

                                    <p style={{ fontSize: "12px" }}>
                                        By clicking below, you agree to roiquant's <a href="https://viewpoints.roiquant.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                            privacy policy</a>, <a href="https://viewpoints.roiquant.com/how-do-we-use-cookies/" target="_blank" rel="noopener noreferrer">
                                            cookies</a>, <a href="https://viewpoints.roiquant.com/user-notice/" target="_blank" rel="noopener noreferrer">
                                            user notice</a> and <a href="https://viewpoints.roiquant.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                                            terms of service</a>.
                                    </p>
                                </div>

                                <div style={{ textAlign: "center" }}>
                                    {
                                        verify.firstName === "" || verify.lastName === "" || verify.email === "" || verify.awsAvailable === "" || verify.howDidYouHearAboutUs === ""
                                            ? (<button className="referralctabutton glintanimation" disabled>Submit pre-application</button>)
                                            : (<button className="referralctabutton glintanimation" onClick={submitVerifyButton}>Submit pre-application</button>)
                                    }
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>

                    {/* show pop up modal here */}

                    <Modal className="getintouchformdiv" show={showCard} onHide={hideCard} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton className="getintouchformheader" style={{ borderBottom: "none" }} />
                        <Modal.Body className="getintouchformbody" >

                            <div>
                                <Form onSubmit={e => handleSubmit(e)}>

                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <Form.Group controlId="formBasicFirstName" style={{ width: "50%", paddingRight: "1.5%" }}>
                                            First name<sup>*</sup>
                                            <Form.Control onChange={handleChange} required maxlength="20" className="inputbox" type="text" value={subscription.firstName} name="firstName" placeholder="Enter your first name" />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicLastName" style={{ width: "50%" }}>
                                            Last name<sup>*</sup>
                                            <Form.Control onChange={handleChange} required maxlength="20" className="inputbox" type="text" value={subscription.lastName} name="lastName" placeholder="Enter your last name" />
                                        </Form.Group>
                                    </div>

                                    <Form.Group controlId="formBasicEmail">
                                        Email<sup>*</sup>
                                        <Form.Control onChange={handleChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required className="inputbox" type="email" value={subscription.email} name="email" placeholder="Enter your email address" />
                                    </Form.Group>

                                    We would like to know you better<sup>*</sup>
                                    <Form.Group controlId="formBasicAbout">
                                        <Form.Control onChange={handleChange} required className="formbox" as="select" value={subscription.knowYouBetter} name="knowYouBetter" placeholder="">
                                            <option></option>
                                            {
                                                knowYouBetter.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    How did you hear about us?<sup>*</sup>
                                    <Form.Group controlId="formBasicAboutUs">
                                        <Form.Control onChange={handleChange} required className="formbox" as="select" value={subscription.hearAboutUs} name="hearAboutUs" placeholder="" >
                                            <option></option>
                                            {
                                                hearAboutUs.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    {/* Function to handle if user select "recommended by a friend or colleague option" or "Other" */}

                                    {
                                        subscription.hearAboutUs === "Recommended by a friend or colleague"
                                            ? (
                                                <Form.Group controlId="formBasicOther">
                                                    <Form.Control onChange={handleChange} required className="inputbox" type="text" value={subscription.recommended} name="recommended" placeholder="Enter your friend's/colleague's name or email" />
                                                </Form.Group>)
                                            : ""
                                    }

                                    {
                                        subscription.hearAboutUs === "Other"
                                            ? (
                                                <Form.Group controlId="formBasicOther">
                                                    <Form.Control onChange={handleChange} required className="inputbox" type="text" value={subscription.other} name="other" placeholder="Please tell us more" />
                                                </Form.Group>)
                                            : ""
                                    }

                                    What is your inquiry about?<sup>*</sup>
                                    <Form.Group controlId="formBasicInquiry">
                                        <Form.Control onChange={handleChange} required className="formbox" as="select" value={subscription.inquiry} name="inquiry" placeholder="" >
                                            <option></option>
                                            {
                                                inquiry.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Form.Group>

                                    {/* Function to handle if user select "Other" */}

                                    {
                                        subscription.inquiry === "Other"
                                            ? (
                                                <Form.Group controlId="formBasicOther">
                                                    <Form.Control onChange={handleChange} required className="inputbox" type="text" value={subscription.others} name="others" placeholder=" Enter your subject of inquiry" />
                                                </Form.Group>)
                                            : ""
                                    }

                                    <Form.Group controlId="formBasicTellUsMore" style={{ width: "100%" }}>
                                        Tell us more<sup>*</sup>
                                        <Form.Control onChange={handleChange} required className="inputbox" type="textarea" value={subscription.tellusmore} name="tellusmore" placeholder="How can we help you?" />
                                    </Form.Group>

                                    <div style={{ textAlign: "center" }}>
                                        {
                                            subscription.email === "" || subscription.firstName === "" || subscription.lastName === "" || subscription.knowYouBetter === "" || subscription.hearAboutUs === "" || (subscription.hearAboutUs === "Recommended by a friend or colleague" && subscription.recommended === "") || (subscription.hearAboutUs === "Other" && subscription.other === "") || subscription.inquiry === "" || (subscription.inquiry === "Other" && subscription.others === "") || subscription.tellusmore === ""
                                                ? (<button className="orderbutton glintanimation" disabled >Get in touch</button>)
                                                : (<button className="orderbutton glintanimation" onClick={handleSubmit}>Get in touch</button>)
                                        }
                                    </div>

                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </>
    )
}

export default Dashboard;